import(/* webpackMode: "eager", webpackExports: ["AppProvider"] */ "/codebuild/output/src2529289614/src/next-app/app/_components/AppProvider.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["Polyfill"] */ "/codebuild/output/src2529289614/src/next-app/app/polyfill.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["CSPostHogProvider"] */ "/codebuild/output/src2529289614/src/next-app/app/posthog.tsx");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2529289614/src/node_modules/material-icons/iconfont/filled.css");
;
import(/* webpackMode: "eager" */ "/codebuild/output/src2529289614/src/node_modules/material-icons/iconfont/outlined.css");
;
import(/* webpackMode: "eager", webpackExports: ["StitchesRegistry"] */ "/codebuild/output/src2529289614/src/pattern-library/src/basicUI/StitchesRegistry.tsx");
